export const OrderActionTypes = {
  CREATE_ORDER_START: "CREATE_ORDER_START",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
  CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",
  GET_ORDERS_START: "GET_ORDERS_START",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",
  GET_ORDER_START: "GET_ORDER_START",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAILURE: "GET_ORDER_FAILURE",
  CRUD_ORDER_STATUS_RESET: "CRUD_ORDER_STATUS_RESET"
};
