export const UserActionTypes = {
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  RESET_ERROR: "RESET_ERROR",
  SIGN_IN_START: "SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_UP_START: "SIGN_UP_START",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  EMAIL_VERIFICATION_START: "EMAIL_VERIFICATION_START",
  EMAIL_VERIFICATION_SUCCESS: "EMAIL_VERIFICATION_SUCCESS",
  EMAIL_VERIFICATION_FAILURE: "EMAIL_VERIFICATION_FAILURE",
  PASSWORD_RESET_REQUEST_START: "PASSWORD_RESET_REQUEST_START",
  PASSWORD_RESET_REQUEST_SUCCESS: "PASSWORD_RESET_REQUEST_SUCCESS",
  PASSWORD_RESET_REQUEST_FAILURE: "PASSWORD_RESET_REQUEST_FAILURE",
  PASSWORD_RESET_START: "PASSWORD_RESET_START",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
  CRUD_USER_STATUS_RESET: "CRUD_USER_STATUS_RESET"
};
