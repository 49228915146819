export const NovelActionTypes = {
  GET_GENRES_START: "GET_GENRES_START",
  GET_GENRES_SUCCESS: "GET_GENRES_SUCCESS",
  GET_GENRES_FAILURE: "GET_GENRES_FAILURE",
  GET_NOVELS_START: "GET_NOVELS_START",
  GET_NOVELS_SUCCESS: "GET_NOVELS_SUCCESS",
  GET_NOVELS_FAILURE: "GET_NOVELS_FAILURE",
  GET_NOVEL_START: "GET_NOVEL_START",
  GET_NOVEL_SUCCESS: "GET_NOVEL_SUCCESS",
  GET_NOVEL_FAILURE: "GET_NOVEL_FAILURE",
  CREATE_NOVEL_START: "CREATE_NOVEL_START",
  CREATE_NOVEL_SUCCESS: "CREATE_NOVEL_SUCCESS",
  CREATE_NOVEL_FAILURE: "CREATE_NOVEL_FAILURE",
  UPDATE_NOVEL_START: "UPDATE_NOVEL_START",
  UPDATE_NOVEL_SUCCESS: "UPDATE_NOVEL_SUCCESS",
  UPDATE_NOVEL_FAILURE: "UPDATE_NOVEL_FAILURE",
  DELETE_NOVEL_START: "DELETE_NOVEL_START",
  DELETE_NOVEL_SUCCESS: "DELETE_NOVEL_SUCCESS",
  DELETE_NOVEL_FAILURE: "DELETE_NOVEL_FAILURE",
  REMOVE_NOVEL: "REMOVE_NOVEL",
  CRUD_NOVEL_STATUS_RESET: "CRUD_NOVEL_STATUS_RESET"
};
